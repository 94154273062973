/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Modal } from '../../../../main/Modal';
import { t } from '../../../../../i18n';
import { KmmButton } from '../../components/KmmButton';
import styles from './styles.module.scss';
import {
  useCloseOnEscape,
  usePathPrefix,
} from '../../../../../constants/hooks';
import { sendKmmFeedback } from '../../utils/api';
import { TextInputWithIcon } from '../../../../common/TextInputWithIcon';
import formInputs from './constant';
import { Textarea } from '../../../../inputs/Textarea';
import { getFromUrl } from '../../../../../utils/getFromUrl';
import { OopsPanel } from '../../../../common/OopsPanel';

interface IProps {
  onClose: () => void;
  onSuccess: () => void;
  titleType: string;
}

const KmmContactModal: FC<IProps> = ({ onClose, onSuccess, titleType }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const formName = 'order_form_kmm_meetup';

  const prefix = usePathPrefix();
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const { name, phone, questions, email } = inputsState;
  const [isSuccess, setIsSuccess] = useState(false);
  const [showOops, setShowOops] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [programmingLanguage, setProgrammingLanguage] = useState('default');

  const options = [
    { value: 'swift', label: 'Swift' },
    { value: 'kotlin', label: 'Kotlin' },
    { value: 'java', label: 'Java' },
    { value: 'other', label: 'Другое' },
  ];

  const path = getFromUrl();

  const validateFields = useCallback(() => {
    const errorsField = {
      name: !name || name.length <= 1,
      phone: !phone || phone.length <= 17,
      email: !email || email.length <= 2,
      programmingLanguage: programmingLanguage === 'default',
    };

    setErrors(errorsField);

    return !Object.values(errorsField).some((val) => val);
  }, [name, phone, email, programmingLanguage, setErrors]);

  const removeError = (
    fieldName: string,
    prevValue: string,
    curValue: string
  ) => {
    if (prevValue !== curValue) {
      const errorsTemp = { ...errors, [fieldName]: false };
      setErrors(errorsTemp);
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nameInput = e.target.name;
    const { value } = e.target;
    if (inputsState[nameInput] !== value) {
      const errorsTemp = { ...errors, [nameInput]: false };
      setErrors(errorsTemp);
    }

    setInputsState((prevState) => ({
      ...prevState,
      [nameInput]: value,
      from: `/${path}`,
    }));
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (!validateFields()) return;

      setIsLoading(true);

      try {
        if (!executeRecaptcha) return;
        const captcha = await executeRecaptcha(formName);

        const res = await sendKmmFeedback({
          name,
          phone,
          email,
          programmingLanguage,
          questions,
          captcha,
        });

        if (res) {
          onSuccess();
          onClose();
          setIsSuccess(true);
        }
      } catch (e) {
        const error = e as AxiosError;
        setErrorMessage(error.response?.data.error);
        setShowOops(true);
      } finally {
        setIsLoading(false);
      }
    },
    [validateFields, setIsLoading, onSuccess, onClose]
  );

  useCloseOnEscape(onClose);

  return (
    <>
      <OopsPanel isOpen={showOops} errorMessage={errorMessage} />

      <Modal onClose={onClose}>
        <div className={styles.modal__content}>
          <div className={styles.modal__close} onClick={onClose}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.439362 0.439339C-0.146454 1.02512 -0.146454 1.97489 0.439362 2.56068L6.87869 9.00001L0.468781 15.4099C-0.117035 15.9957 -0.117035 16.9454 0.468781 17.5312C1.0546 18.117 2.0043 18.117 2.59012 17.5312L9.00003 11.1213L15.2886 17.4099C15.8744 17.9957 16.8241 17.9957 17.4099 17.4099C17.9958 16.8241 17.9958 15.8744 17.4099 15.2886L11.1214 9.00001L17.4394 2.68198C18.0252 2.0962 18.0252 1.14646 17.4394 0.560677C16.8535 -0.0251083 15.9038 -0.0251083 15.318 0.560677L9.00003 6.87867L2.5607 0.439339C1.97488 -0.146446 1.02518 -0.146446 0.439362 0.439339Z"
                fill="#55439D"
              />
            </svg>
          </div>

          <div>
            <div className={styles.title}>{t(titleType)}</div>

            <div className={styles.desc}>{t('cases.kmm.form.desc')}</div>
          </div>

          <form
            className={styles.modal__inputs}
            id={formName}
            onSubmit={onSubmit}
          >
            {formInputs.map(
              ({ nameInput, label, icon, id }) =>
                nameInput !== 'comment' && (
                  <TextInputWithIcon
                    name={nameInput}
                    id={id}
                    value={inputsState[nameInput] ?? ''}
                    handler={onChange}
                    label={label}
                    icon={`${prefix}${icon}`}
                    hasError={errors[nameInput]}
                    key={nameInput}
                    isSuccess={isSuccess}
                    removeError={removeError}
                  />
                )
            )}

            <div className={styles.selectWrapper}>
              <select
                value={programmingLanguage}
                id="kmm-programming-language"
                onChange={(e) => {
                  setProgrammingLanguage(e.target.value);
                  if (e.target.value !== 'default') {
                    const errorsTemp = {
                      ...errors,
                      programmingLanguage: false,
                    };
                    setErrors(errorsTemp);
                  }
                }}
                className={`
            ${styles.select}
            ${
              programmingLanguage === 'default'
                ? styles.select__placeholder
                : ''
            }
            ${errors.programmingLanguage ? styles.select__error : ''}
           `}
                style={{
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: "url('/case/internship/buttons/close.svg')",
                  backgroundColor: '#F4F3F8',
                  backgroundPosition: '98.5% 50%',
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                }}
              >
                <option value="default" disabled hidden>
                  Твой основной язык программирования
                </option>
                {options.map((optionLocal) => (
                  <option value={optionLocal.value} key={optionLocal.label}>
                    {optionLocal.label}
                  </option>
                ))}
              </select>
              {errors.programmingLanguage && (
                <div className={styles.error}>
                  {t('contacts.fill_this_field')}
                </div>
              )}
            </div>

            <div className={styles.textarea}>
              <Textarea
                name="questions"
                id="kmm-questions"
                value={questions ?? ''}
                handler={onChange}
                placeholder="cases.kmm.form.textarea"
              />
            </div>

            <p className={styles.personel_area}>
              Нажимая кнопку, вы даете согласие на обработку своих{' '}
              <a href="/case/restocare/terms.pdf">Персональных данных</a>
            </p>
            <KmmButton isLoading={isLoading} disabled={isLoading}>
              {t('cases.delivery.contacts.send')}
            </KmmButton>

            <div id="html_element" />
            <input type="hidden" name="from" id="from" value={path} />
          </form>
        </div>
      </Modal>
    </>
  );
};

export { KmmContactModal };
