/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { KmmButton } from '../../components/KmmButton';

interface IProps {
  showModal: () => void;
  onScroll: (to: number) => void;
  setModalTitle: (value: string) => void;
}

const KmmFooter: FC<IProps> = ({ showModal, onScroll, setModalTitle }) => (
  <div className={styles.wrap}>
    <div className={styles.title_text}>
      <div className={styles.date}>{t('cases.kmm.footer.date')}</div>
      <h3>{t('cases.kmm.footer.title')}</h3>
      <h4>{t('cases.kmm.footer.subtitle')}</h4>
      <KmmButton
        onClick={() => {
          setModalTitle('cases.kmm.footer.modalTitle');
          showModal();
        }}
      >
        {t('cases.kmm.footer.button')}
      </KmmButton>
    </div>
  </div>
);

export { KmmFooter };
