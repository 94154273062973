import { getGa, getUtmData } from '../../restocare/utils/ga';
import { sendKmmRequest } from '../../../../utils/api';

export const sendKmmFeedback = ({
  phone,
  name,
  programmingLanguage,
  questions,
  email,
  captcha,
  from,
}: Record<string, any>) => {
  const referrer = document?.referrer || '';
  const googleId = getGa();
  const utm = getUtmData();

  return sendKmmRequest({
    phone,
    name,
    programmingLanguage,
    questions,
    captcha,
    ...utm,
    email,
    googleId,
    referrer,
    from,
  });
};
