/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface FitProps {
  icon: string;
  title: string;
  description: string;
}

const FitItem: FC<FitProps> = ({ icon, title, description }) => (
  <div className={styles.fit_item}>
    <img src={icon} alt="" className={styles.fit_item_image} />
    <div className={styles.fit_item_text}>
      <p className={styles.fit_item_title}>{t(title)}</p>
      <p className={styles.fit_item_description}>{t(description)}</p>
    </div>
  </div>
);
const fitItemsIcons = [
  '/case/kmm/location.svg',
  '/case/kmm/calendar.svg',
  '/case/kmm/clock.svg',
  '/case/kmm/group.svg',
  '/case/kmm/thumb_up.svg',
];

const HowIsItDone = () => (
  <div className={styles.wrap}>
    <div className={styles.content}>
      <div className={styles.title_content}>
        <div className={styles.title}>
          <h3>{t('cases.kmm.howIsItDone.title')}</h3>
        </div>
      </div>
      <div className={styles.fit_content}>
        <div className={styles.fit_list}>
          {fitItemsIcons.map((fitItemIcon, index) => (
            <FitItem
              key={`howIsItDone${index}`}
              icon={fitItemIcon}
              title={`cases.kmm.howIsItDone.fitItem_${index + 1}.title`}
              description={`cases.kmm.howIsItDone.fitItem_${
                index + 1
              }.description`}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export { HowIsItDone };
