/* eslint-disable react/button-has-type,react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface FitProps {
  title: string;
}

const FitItem: FC<FitProps> = ({ title }) => (
  <div className={styles.fit_item}>
    <div className={styles.fit_item_content}>
      <div className={styles.fit_item_title}>{t(title)}</div>
    </div>
    <img
      src="/case/kmm/line.svg"
      alt=""
      className={styles.fit_item_separator}
    />
  </div>
);
const fitItems = [1, 2, 3, 4];

const WhenToGo = () => (
  <div className={styles.wrap}>
    <div className={styles.title_content}>
      <div className={styles.title_text}>
        <h3>{t('cases.kmm.whenToGo.title')}</h3>
      </div>
    </div>
    <div>
      {fitItems.map((fitItem, index) => (
        <FitItem
          key={`WhenToGo${index}`}
          title={`cases.kmm.whenToGo.reason_${fitItem}`}
        />
      ))}
      <div className={styles.notice}>
        <h4>{t('cases.kmm.whenToGo.notice.title')}</h4>
        <p>{t('cases.kmm.whenToGo.notice.description')}</p>
      </div>
    </div>
  </div>
);

export { WhenToGo };
