/* eslint-disable jsx-a11y/anchor-is-valid,react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styles from './styles.module.scss';
import { t } from '../../../../../i18n';
import { Client } from '../../components/Client';

const OurClients = () => {
  const clients = [
    {
      title: 'profi.ru',
      url: 'https://profi.ru/',
      logo: '/case/kmm/clients/profi.svg',
    },
  ];
  return (
    <div className={styles.wrap}>
      <a id="advantages" />
      <div className={styles.title}>
        <h3>{t('cases.kmm.ourClients.title')}</h3>
      </div>
      <div className={styles.clients}>
        {clients.map((item, index) => (
          <Client
            key={`ourClients${index}`}
            title={item.title}
            url={item.url}
            logo={item.logo}
          />
        ))}
      </div>
    </div>
  );
};

export { OurClients };
