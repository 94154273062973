import React from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

const WhyKotlin = () => (
  <div className={styles.wrap}>
    <div className={styles.title_content}>
      <div className={styles.title}>
        <h3>{t('cases.kmm.whyKotlin.title')}</h3>
      </div>
      <div className={styles.desc}>
        <div>
          <h4>{t('cases.kmm.whyKotlin.desc_1')}</h4>
        </div>
        <div>
          <h4>{t('cases.kmm.whyKotlin.desc_2')}</h4>
        </div>
        <div>
          <h4>{t('cases.kmm.whyKotlin.desc_3')}</h4>
        </div>
        <div>
          <h4>{t('cases.kmm.whyKotlin.desc_4')}</h4>
        </div>
      </div>
    </div>
  </div>
);

export { WhyKotlin };
