/* eslint-disable no-param-reassign */
/* eslint-disable radix */
import React, { FC, useCallback, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../templates/MainLayout';
import { KmmTitle } from '../../components/directions/kmm/screens/KmmTitle';
import { KmmContactModal } from '../../components/directions/kmm/screens/KmmContactModal';
import { KmmSuccessModal } from '../../components/directions/kmm/screens/KmmSuccessModal';
import { IDirectionTagsQuery } from '../../queries/directionTags/types';
import { KmmFooter } from '../../components/directions/kmm/screens/KmmFooter';
import styles from './access/css/styles.module.scss';
import { useSSRSafeWindow } from '../../constants/hooks';
import { WhoShouldGo } from '../../components/directions/kmm/screens/WhoShouldGo';
import { WhenToGo } from '../../components/directions/kmm/screens/WhenToGo';
import { KmmFAQ } from '../../components/directions/kmm/screens/KmmFAQ';
import { OurClients } from '../../components/directions/kmm/screens/OurClients';
import { ReCaptchaProvider } from '../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery,
  { locale: string; tag: string; page: number }
>;

const KmmPage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'ru', tag = '', page = 0 },
}) => {
  const direction = data.directionTags.edges.find((edge) =>
    location.pathname.includes(edge.node.frontmatter.url)
  );
  const [isModalShown, setIsModalShown] = useState(false);
  const [isSuccessShown, setIsSuccessShown] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const toggleModal = useCallback(
    () => setIsModalShown(!isModalShown),
    [isModalShown, setIsModalShown]
  );

  const toggleSuccess = useCallback(
    () => setIsSuccessShown(!isSuccessShown),
    [isSuccessShown, setIsSuccessShown]
  );

  const win = useSSRSafeWindow();

  const onScroll = (to: number) => {
    let i = parseInt(String(win?.pageYOffset));
    if (i !== to) {
      to = parseInt(String(to));
      if (i < to) {
        const int = setInterval(() => {
          if (i > to - 20) i += 1;
          else if (i > to - 40) i += 3;
          else if (i > to - 80) i += 8;
          else if (i > to - 160) i += 18;
          else if (i > to - 200) i += 24;
          else if (i > to - 300) i += 40;
          else i += 60;
          win?.scroll(0, i);
          if (i >= to) clearInterval(int);
        }, 15);
      } else {
        const int = setInterval(() => {
          if (i < to + 20) i -= 1;
          else if (i < to + 40) i -= 3;
          else if (i < to + 80) i -= 8;
          else if (i < to + 160) i -= 18;
          else if (i < to + 200) i -= 24;
          else if (i < to + 300) i -= 40;
          else i -= 60;
          win?.scroll(0, i);
          if (i <= to) clearInterval(int);
        }, 15);
      }
    }
  };

  return (
    <ReCaptchaProvider>
      <MainLayout
        locale={locale}
        title={direction?.node.frontmatter.name ?? ''}
      >
        <div className={styles.wrap_kmm}>
          <KmmTitle
            title={direction?.node.frontmatter.name ?? ''}
            showModal={toggleModal}
            onScroll={onScroll}
            setModalTitle={setModalTitle}
          />
          <WhoShouldGo showModal={toggleModal} setModalTitle={setModalTitle} />
          <WhenToGo />
          <KmmFAQ />
          <OurClients />

          <KmmFooter
            showModal={toggleModal}
            onScroll={onScroll}
            setModalTitle={setModalTitle}
          />
          {isModalShown && (
            <KmmContactModal
              onClose={toggleModal}
              onSuccess={toggleSuccess}
              titleType={modalTitle}
            />
          )}
          {isSuccessShown && <KmmSuccessModal onClose={toggleSuccess} />}
        </div>
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/tags/" } }
    ) {
      ...DirectionTagsFields
    }
  }
`;

export default KmmPage;
