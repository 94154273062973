import React, { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  title: string;
  url: string;
  logo: string;
}

const Client: FC<IProps> = ({ title, url, logo }) => {
  const getLogo = () => {
    if (logo === '') {
      return '/case/kmm/clients/default.svg';
    }
    return logo;
  };

  return (
    <div className={styles.card}>
      <div>
        <a href={url} target="_blank" rel="noreferrer">
          <img
            src={getLogo()}
            alt={title}
            className={logo === '' ? styles.card__default : styles.card__logo}
          />
        </a>
      </div>
    </div>
  );
};

export { Client };
