/* eslint-disable react/button-has-type,react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';

interface FitProps {
  icon: string;
  title: string;
}

interface IProps {
  showModal: () => void;
  setModalTitle: (value: string) => void;
}

const FitItem: FC<FitProps> = ({ icon, title }) => {
  const line = '/case/kmm/line.svg';
  return (
    <div className={styles.fit_item}>
      <div className={styles.fit_item_content}>
        <img src={icon} alt="" className={styles.fit_item_image} />
        <div className={styles.fit_item_title}>{t(title)}</div>
      </div>
      <img src={line} alt="" className={styles.fit_item_separator} />
    </div>
  );
};
const fitItemsIcons = [
  '/case/kmm/junior.svg',
  '/case/kmm/middle.svg',
  '/case/kmm/senior.svg',
];
const whoShouldGoImage = '/case/kmm/WhoShouldGo.png';

const WhoShouldGo: FC<IProps> = ({ showModal, setModalTitle }) => (
  <div className={styles.wrap}>
    <div className={styles.image}>
      <img src={whoShouldGoImage} alt="" />
    </div>

    <div>
      <div className={styles.title_content}>
        <div className={styles.title_text}>
          <h3>{t('cases.kmm.whoShouldGo.title')}</h3>
          <p>{t('cases.kmm.whoShouldGo.paragraph_1')}</p>
          <p>{t('cases.kmm.whoShouldGo.paragraph_2')}</p>
        </div>
      </div>
      <div className={styles.title_content}>
        <div className={`${styles.title_text} ${styles.subtitle}`}>
          <h3>{t('cases.kmm.whoShouldGo.inviteTitle')}</h3>
          <div>
            {fitItemsIcons.map((fitItemIcon, index) => (
              <FitItem
                key={`WhoShouldGo${index}`}
                icon={fitItemIcon}
                title={`cases.kmm.whoShouldGo.guestType_${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.title_content}>
        <div className={`${styles.title_text} ${styles.subtitle}`}>
          <h3>{t('cases.kmm.whoShouldGo.beginnerTitle')}</h3>
          <p>{t('cases.kmm.whoShouldGo.beginnerParagraph_1')}</p>
          <p>{t('cases.kmm.whoShouldGo.beginnerParagraph_2')}</p>
          <p>{t('cases.kmm.whoShouldGo.beginnerParagraph_3')}</p>
          <ul>
            <li>{t('cases.kmm.whoShouldGo.beginnerLi_1')}</li>
            <li>{t('cases.kmm.whoShouldGo.beginnerLi_2')}</li>
            <li>{t('cases.kmm.whoShouldGo.beginnerLi_3')}</li>
          </ul>
        </div>
      </div>
      <div className={styles.button_block}>
        {/* <KmmButton onClick={() => { */}
        {/*  setModalTitle('cases.kmm.whoShouldGo.beginnerModalTitle'); */}
        {/*  showModal(); */}
        {/* }} */}
        {/* > */}
        {/*  {t('cases.kmm.whoShouldGo.beginnerButton')} */}
        {/* </KmmButton> */}
        <p>{t('cases.kmm.whoShouldGo.beginnerSubTitle')}</p>
      </div>
    </div>
  </div>
);

export { WhoShouldGo };
