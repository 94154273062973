/* eslint-disable no-param-reassign */
/* eslint-disable radix */
/* eslint-disable react/button-has-type */
import React, { FC } from 'react';
import { t } from '../../../../../i18n';
import styles from './styles.module.scss';
import { KmmButton } from '../../components/KmmButton';
import { usePathPrefix } from '../../../../../constants/hooks';
import { KmmBreadcrumbs } from '../../components/KmmBreadcrumbs';
import { WhyKotlin } from '../WhyKotlin';
import { HowIsItDone } from '../HowIsItDone';

interface IProps {
  title: string;
  showModal: () => void;
  onScroll: (to: number) => void;
  setModalTitle: (value: string) => void;
}

const KmmTitle: FC<IProps> = ({
  title,
  showModal,
  onScroll,
  setModalTitle,
}) => {
  const prefix = usePathPrefix();
  return (
    <div className={styles.wrap}>
      <div className={styles.breadcrumbs}>
        <div>
          <KmmBreadcrumbs title={title} />
        </div>
      </div>
      <div className={styles.title_content}>
        <div className={styles.title_text}>
          <h3>{t('cases.kmm.header.name')}</h3>
          <h4>{t('cases.kmm.header.title')}</h4>
          <p>{t('cases.kmm.header.desc')}</p>
          <KmmButton
            onClick={() => {
              setModalTitle('cases.kmm.header.modalTitle');
              showModal();
            }}
          >
            {t('cases.kmm.header.button')}
          </KmmButton>
        </div>
        <div className={styles.image}>
          <img src={`${prefix}/case/kmm/title.png`} alt="" />
        </div>
      </div>
      <WhyKotlin />
      <HowIsItDone />
    </div>
  );
};

export { KmmTitle };
